import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Box, Link as RebassLink } from 'rebass/styled-components'

import Container from '../components/container'
import { ReferenceCarousel } from '../components/reference-carousel'
import reliefBg from '../images/relief-bg.png'

export const ReferenceSection = ({ ctaLabel, ctaLink }) => {
  const data = useStaticQuery(graphql`
    {
      referenceImages: allFile(
        filter: { absolutePath: { regex: "/\/reference\//" } }
        sort: { fields: name }
        limit: 5
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
    }
  `)
  const referenceImages = data.referenceImages.edges.map(({ node }) => node)

  return (
    <Box
      variant="sections.reference"
      sx={{
        pb: [5, 5, 6],
        backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 75%), url(${reliefBg})`,
        backgroundSize: '100%, 50%',
      }}
    >
      <Container>
        <ReferenceCarousel images={referenceImages} />

        {ctaLabel && ctaLink && (
          <Box
            sx={{
              display: 'grid',
              width: '100%',
              justifyContent: 'center',
              mt: 4,
            }}
          >
            <RebassLink as={Link} variant="linkButton" to={ctaLink}>
              {ctaLabel}
            </RebassLink>
          </Box>
        )}
      </Container>
    </Box>
  )
}

ReferenceSection.propTypes = {
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
}
