import React from 'react'
import { Box, Image } from 'rebass/styled-components'

import pfLogo from '../images/pf-logo-white.svg'
import pfTree from '../images/pf-tree.svg'

export const PF = () => {
  const thisYearString = new Date().getFullYear()
  const nextYear = Number(thisYearString) + 1

  if (new Date() < new Date(thisYearString, 11)) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', 'auto auto'],
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        columnGap: 2,
        rowGap: 4,
        maxWidth: '65rem',
        position: 'relative',
        m: 'auto',
        p: 5,
        bg: 'blue',
        color: 'white',
        zIndex: 1,
        '&:after': {
          content: '""',
          position: 'absolute',
          width: '95%',
          height: '100%',
          left: '50%',
          backgroundColor: 'rgba(20, 20, 40, 0.2)',
          borderRadius: '1rem',
          filter: 'blur(1rem)',
          transform: 'translate(-50%, 1.5rem)',
          zIndex: -1,
        },
      }}
    >
      <Box>
        <p>
          Přejeme Vám příjemné prožití vánočních svátků{' '}<br />a do nového roku pevné
          zdraví, štěstí a úspěchů.
        </p>
        <p>
          Děkujeme za Vaši dosavadní přízeň{' '}<br />a těšíme se na další spolupráci v
          roce {nextYear}.
        </p>
        <br />

        <p>
          <strong>Pavel Šafr</strong>
        </p>

        <Image
          src={pfLogo}
          alt="Frypo wood design"
          sx={{ maxWidth: '200px' }}
        />
      </Box>

      <Box>
        <Image src={pfTree} alt="" sx={{ width: '220px' }} />
      </Box>
    </Box>
  )
}
