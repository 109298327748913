import React from 'react'
import { Box, Image } from 'rebass/styled-components'

import pfLogo from '../images/pf-logo-white.svg'

export const Holiday = () => {
  if (new Date() > new Date(2023, 7, 31)) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        gridTemplateColumns: ['1fr', '1fr'],
        alignItems: 'center',
        justifyContent: 'stretch',
        justifyItems: 'stretch',
        columnGap: 2,
        rowGap: 4,
        maxWidth: '65rem',
        position: 'relative',
        m: 'auto',
        p: [4, 5],
        bg: 'blue',
        color: 'white',
        zIndex: 1,
        '&:after': {
          content: '""',
          position: 'absolute',
          width: '95%',
          height: '100%',
          left: '50%',
          backgroundColor: 'rgba(20, 20, 40, 0.2)',
          borderRadius: '1rem',
          filter: 'blur(1rem)',
          transform: 'translate(-50%, 1.5rem)',
          zIndex: -1,
        },
      }}
    >
      <Box>
        <p>
          <strong>Celozávodní dovolená</strong>
        </p>
        <p>
          Od 16.8. do 30.8. 2023 čerpá celý náš tým dovolenou. Vaše objednávky
          vyřídíme od 31.8.
        </p>
        <br />

        <p>
          <strong>Děkujeme za pochopení</strong>
        </p>

        <Image
          src={pfLogo}
          alt="Frypo wood design"
          sx={{ maxWidth: '200px' }}
        />
      </Box>
    </Box>
  )
}
