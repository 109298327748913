import React from 'react'
import { Box, Image, Link, Text } from 'rebass/styled-components'

import Container from '../components/container'
import computerImage from '../images/computer-image.png'

export const StoreSection = () => {
  return (
    <Box
      variant="sections.store"
      sx={{
        backgroundImage: `linear-gradient(95.94deg, #49B96A 21.42%, #389454 78.68%)`,
      }}
    >
      <Container>
        <Box variant="sections.store.boxContent">
          <Box
            variant="sections.store.textContent"
          >
            <Text sx={{ fontSize: '1.25rem' }} as="p">
              Rozjeli jsme pro vás nový <strong>e-shop</strong>{' '}
              s&nbsp;kvalitními produkty <strong>Arboritec a Verilegno</strong>{' '}
              a jejich příslušenstvím.
            </Text>

            <Text sx={{ fontSize: '1.25rem' }} as="p">
              <strong>Tešíme se na vás.</strong>
            </Text>

            <Link
              variant="linkButton"
              href="https://eshop.frypo.cz/"
              target="_blank"
            >
              eshop.frypo.cz
            </Link>
          </Box>

          <Image
            variant="sections.store.image"
            src={computerImage}
            alt=""
            aria-hidden
          />
        </Box>
      </Container>
    </Box>
  )
}
