import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Image } from 'rebass/styled-components'

import Container from '../components/container'
import reliefBg from '../images/relief-bg.png'

export const IntroSection = ({ children, image }) => {
  return (
    <Box
      variant="sections.intro"
      sx={{
        backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 75%), url(${reliefBg})`,
        backgroundSize: '100%, 50%',
      }}
    >
      <Container>
        <Box variant="sections.intro.boxContent">
          <Image
            variant="sections.intro.image"
            src={image}
            alt=""
            aria-hidden
          />

          <Box variant="sections.intro.textContent">
            <Card sx={{ mt: -6, fontSize: '1.125rem', zIndex: 1 }}>
              {children}
            </Card>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

IntroSection.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
}
