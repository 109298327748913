import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Hero } from '../components/hero'
import { PF } from '../components/pf'
// import { Seminar } from '../components/seminar'
import { IntroSection } from '../sections/intro'
import { StoreSection } from '../sections/store'
import { ReferenceSection } from '../sections/reference'
import { Holiday } from '../components/holiday'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: file(relativePath: { regex: "/index-bg.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
      introImage: file(relativePath: { regex: "/intro-image.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `)
  const heroBg = data.heroBg.childImageSharp.gatsbyImageData.images.fallback.src
  const introImage =
    data.introImage.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO title="Pečujeme o dřevo" />

      <Hero
        image={heroBg}
        title="Pečujeme o&nbsp;dřevo"
        text="Pomáháme lidem vybrat produkty a postupy
            <strong>povrchové úpravy dřeva</strong>, které mu zajistí dlouhou
            životnost."
        ctaLabel="Více o firmě"
        ctaLink="/nase-vize"
        fullHeight
      />

      <IntroSection image={introImage}>
        <p>
          <strong>
            Společnost FRYPO wood design je 100% česká rodinná firma
          </strong>
          , která spolupracuje s italským výrobcem laků na dřevo Verinlegno od
          roku 2012. Filozofie firmy, přístup k zákazníkům, odpovědnost k
          životnímu prostředí je u obou společností velmi podobná.
        </p>

        <p>
          <strong>
            Prodáváme na trh vysoce kvalitní, inovativní a bezpečné produkty.
          </strong>
          Tento přístup vychází z filozofie, která je orientována na pružnost,
          potřeby zákazníků a odpovědnost. Do celého procesu výroby vstupujeme s
          vědomím bezpečnosti a šetrnosti k životnímu prostředí.
        </p>

        <p>
          Díky technickým znalostem materiálů s použitím inovativních metod a
          technologií při výrobě lze vytvářet{' '}
          <strong>produkty s nízkým dopadem na životní prostředí</strong>.
        </p>
      </IntroSection>

      <PF />

      <Holiday />

      {/* <Seminar /> */}

      <ReferenceSection ctaLabel="Více referencí" ctaLink="/reference" />

      <StoreSection />
    </Layout>
  )
}

export default IndexPage
