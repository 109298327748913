import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from 'rebass/styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import arrowLeft from '../images/carbon_arrow-left-blue.svg'
import arrowRight from '../images/carbon_arrow-right-blue.svg'

export const ReferenceCarousel = ({ images }) => {
  return (
    <CarouselProvider
      naturalSlideWidth={1040}
      naturalSlideHeight={672}
      totalSlides={images.length}
      style={{ position: 'relative' }}
      infinite
    >
      <Slider>
        {images &&
          images.map((image, i) => {
            const gatsbyImage = getImage(image)
            return (
              <Slide index={i}>
                <Image
                  as={GatsbyImage}
                  image={gatsbyImage}
                  alt=""
                  sx={{ width: '100%' }}
                />
              </Slide>
            )
          })}
      </Slider>

      <Box variant="carousel.buttonLeft" as={ButtonBack}>
        <Image src={arrowLeft} alt="Předchozí" />
      </Box>
      <Box variant="carousel.buttonRight" as={ButtonNext}>
        <Image src={arrowRight} alt="Následující" />
      </Box>

      <Box variant="carousel.dotGroup">
        {images &&
          images.map((image, i) => (
            <Box variant="carousel.dot" as={Dot} slide={i}>
              <Box variant="carousel.dotLine" />
            </Box>
          ))}
      </Box>
    </CarouselProvider>
  )
}

ReferenceCarousel.propTypes = {
  images: PropTypes.array,
}
